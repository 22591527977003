// App imports
import { TheDotRouter } from 'routes';
import { MainProvider } from 'context';
import './styles.scss';

// Third-party imports
import { HashRouter as Router } from 'react-router-dom';

export const App = () => {
  return (
    <MainProvider>
      <Router>
        <div className="App">
          <TheDotRouter/>
        </div>
      </Router> 
    </MainProvider>
  );
}
