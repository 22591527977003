// React imports
import { useState, useEffect, useContext, createContext } from 'react';

const BuildingsDataContext: React.Context<any> = createContext(null)

export const useBuildingsData = () => {
	return (
		useContext(BuildingsDataContext)
	)
}

export const BuildingsDataProvider = ({children}: any) => {
	const [ buildingsData, setBuildingData ] = useState<any>(null);

	useEffect(() => {
	  const fetchData = async () => {
	  	const url = `
	    	${process.env.REACT_APP_API_URL}/
	    	buildings_api
	    `.replace(/\s/g, '')
	    const res = await fetch(url);
	    const receivedData = await res.json();
	    setBuildingData(receivedData[0]);
	  }
	  fetchData();
	}, []);

	return (
		<BuildingsDataContext.Provider value={{ buildingsData }}>
			{children}
		</BuildingsDataContext.Provider>
	)
}

BuildingsDataContext.displayName = "BuildingsDataContext";