// React imports
import { useRef, useEffect, useContext, createContext } from 'react';

// Context imports
import { useMapbox } from '../mapbox';

const GoogleContext: React.Context<any> = createContext(null);

export const useGoogle = () => {
  return (
    useContext(GoogleContext)
  )
}

export const GoogleProvider = ({children}: any) => {
  const googleMapRef = useRef<any>(null);
  const { viewport } = useMapbox();
  const { latitude, longitude, zoom } = viewport;

  useEffect(() => {
    if (googleMapRef.current) {
      googleMapRef.current.panTo({ latitude, longitude });
      googleMapRef.current.setZoom(zoom);
    }
  }, [viewport]);
  
  return (
    <GoogleContext.Provider value={{ googleMapRef }}>
      {children}
    </GoogleContext.Provider>
  )
}

GoogleContext.displayName="GoogleContext";