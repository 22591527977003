// App imports
import { LayerSelector } from './layers';
import './styles.scss';

// Context imports
import { useVisibility } from 'context/visibility';

export const Selectors = () => {
	const { 
		buildingVisibility, setBuildingVisibility,
		landscapePointsVisibility, setLandscapePointsVisibility,
		boundaryVisibility, setBoundaryVisibility,
		streetsVisibility, setStreetsVisibility,
	} = useVisibility();

	return (
		<div className="selectors-wrapper">
			<LayerSelector
				activeLayer={boundaryVisibility}
				setActiveLayer={setBoundaryVisibility}
				name={"Boundary"}
			/>
			<LayerSelector
				activeLayer={streetsVisibility}
				setActiveLayer={setStreetsVisibility}
				name={"Streets"}
			/>
			<LayerSelector
				activeLayer={landscapePointsVisibility}
				setActiveLayer={setLandscapePointsVisibility}
				name={"Lanscape"}
			/>
			<LayerSelector
				activeLayer={buildingVisibility}
				setActiveLayer={setBuildingVisibility}
				name={"Buildings"}
			/>
		</div>
	)
}

Selectors.displayName="Selectors";