// App imports
import { BarsSizesProvider } from './bars';
import { GaugeSizesProvider } from './gauge';

export const SizesProvider = ({children}: any) => {
  return (
    <BarsSizesProvider>
    <GaugeSizesProvider>
      {children}
    </GaugeSizesProvider>
    </BarsSizesProvider>
  )
}

SizesProvider.displayName="SizesProvider";