// React imports
import { useState } from 'react';

// App imports
import { Selectors } from './selectors';
import './styles.scss';

export const Right = ({ setPoiFiltersVisibility }: any) => {
	const [ activateFilters, setActivateFilters	] = useState(false);
	
	return (
		<div className="right-wrapper">
			<div className="right-item" onClick={() => setPoiFiltersVisibility((prev: boolean) => !prev)}>
				<img src={process.env.PUBLIC_URL + "/static/right/filter.svg"} alt="filters" width="40px" height="40px"/>
			</div>
			<div 
				className="right-item" 
				onClick={() => setActivateFilters((prev: boolean) => !prev)}		
			>
				<img src={process.env.PUBLIC_URL + "/static/right/layers.svg"} alt="layers-selector" width="40px" height="40px"/>
			</div>
			{activateFilters && <Selectors/>}
		</div>
	)
}


Right.displayName="Right";