// App imports
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const Contact = () => {
	const navigate = useNavigate();
	return (
		<div className="header-contacts">
			<div onClick={() => navigate("/")}>Home</div>
			<div>About Us</div>
			<div>Contact Us</div>
			<div>FAQ</div>
		</div>
	)
}

Contact.displayName="Contact";