// Context imports
import { useStylesApi } from 'context/api/styles';

// Third party imports
import { Source, Layer } from 'react-map-gl';

export const Points = () => {
	const { fetchData, getTilesUrl } = useStylesApi();
	
	const tableSchema = "kuwait";
	const tableName = "points_business";

	const url = getTilesUrl(tableSchema, tableName)

	const layerStyle: any = {
	    id: "point-layer",
	    type: "circle",
	    source: "kuwait-business",
	    'source-layer': "default",
	    paint: {
	      'circle-radius': 3,
	      'circle-color': 'rgba(222, 222, 0, 0.2)',
	    }
	};

	return (
		<Source 
			id="kuwait-business" 
			type="vector" 
			tiles={[ url ]}
		>
			<Layer {...layerStyle} />
		</Source>
	)
}

Points.displayName="Points"