import { BuildingsDataProvider } from './buildings';

export const DataApiProvider = ({ children }: any) => {
	return (
		<BuildingsDataProvider>
			{children}
		</BuildingsDataProvider>
	)
}

DataApiProvider.displayName="DataApiProvider";