// React imports
import { useEffect, useRef, useState } from 'react';

// App imports
import { Wrapper } from '../wrapper';
// import { Population } from './population';
import { Tiles } from './tiles';
import './styles.scss';

// Context imports
import { useMapbox } from 'context/mapbox';

// Third-party imports
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import OSM from 'ol/source/OSM';
import { useGeographic } from 'ol/proj';

export const OpenLayersMap = () => {
  useGeographic();
  const mapRef = useRef(null);

  const { viewport } = useMapbox();
  const { latitude, longitude, zoom } = viewport;
  
  const [ map, setMap ] = useState<any>(null);
  const [ mapLoaded, setMapLoaded ] = useState(false);

  useEffect(() => {
    const currentMap: any = mapRef.current;
    
    const initialMap: any = new Map({
      target: currentMap,
      layers: [ new TileLayer({source: new OSM()}) ],
      view: new View({center: [ longitude, latitude ], zoom: zoom}),
      controls: [],
    });
    setMap(initialMap);
    setMapLoaded(true);
    return () => initialMap.setTarget(null);
  }, [ latitude, longitude, zoom ]);

  return (
    <Wrapper>
      <div ref={mapRef} className="ol-map-wrapper">
      </div>
      {/*<Population map={map} mapLoaded={mapLoaded} />*/}
      <Tiles map={map} mapLoaded={mapLoaded} />
    </Wrapper>
  );
};

OpenLayersMap.displayName="OpenLayersMap";