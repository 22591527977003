// App imports
import './styles.scss';

export const Subtitle = () => {
	return (
		<div className="intro-subtitle">
			Choose the map platform that better fits with you
		</div>
	)
}

Subtitle.displayName="Subtitle";