// App imports
import { StylesApiProvider } from './styles';
import { DataApiProvider } from './data';
import { GoogleApiProvider } from './google';

export const ApiProvider = ({children}: any) => {
  return (
    <StylesApiProvider>
    <DataApiProvider>
    <GoogleApiProvider>
      {children}
    </GoogleApiProvider>
    </DataApiProvider>
    </StylesApiProvider>
  )
}

ApiProvider.displayName="ApiProvider";