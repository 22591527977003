// App imports
import { Gauge } from './gauge';
import { Bars } from './bars';

// Context imports
import { useMask } from 'context/maps/mask';

const serviceColors: any = {
    attractions: "rgba(255, 0, 0, 1)",
    eating_drinking: "rgba(139, 0, 0, 1)",
    financial: "rgba(128, 0, 128, 1)",
    government: "rgba(255, 255, 0, 1)",
    health: "rgba(0, 128, 0, 1)",
    industrial: "rgba(128, 128, 128, 1)",
    lodging: "rgba(0, 0, 255, 1)",
    place_of_worship: "rgba(255, 165, 0, 1)",
    service: "rgba(154, 205, 50, 1)",
    shops_stores: "rgba(245, 245, 220, 1)",
    transportation: "rgba(173, 216, 230, 1)"
};


export const Stats = () => {
	const { maskProperties } = useMask();
	const data = maskProperties.map((item: any) => item.properties);

	return (
		<div 
			style={{
				position: "absolute", 
				zIndex: "10", 
				right: "20px", 
				bottom: "40px", 
				width: "400px", 
				height: "200px", 
				backgroundColor: "rgba(33, 154, 208, 0.5)",
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				paddingRight: "20px",
				paddingLeft: "20px",
				borderRadius: "10px",
				border: "2px solid rgba(255, 255, 255, 1)",

			}}
		>
			<div style={{display: "grid", gridTemplateRows: "min-content auto"}}>
				<div style={{paddingTop: "10px", paddingLeft: "5px", color: "rgba(255, 255, 255, 1)", fontSize: "1.2em"}}>Business in Kuwait</div>
				<Gauge data={data} name="service" colors={serviceColors}/>
			</div>
			<Bars data={data} name="service" colors={serviceColors}/>
		</div>
	)
}