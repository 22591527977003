// App imports
import { Contact } from './contact';
import './styles.scss';

export const Header = () => {
	return (
		<div className="header-wrapper">
			<Contact/>
			<img 
				className="logo-wrapper"
				src={process.env.PUBLIC_URL + '/static/logos/logo.png'}
				alt="logo-q8" 
			/>
			<div className="header-user">
				<img 
					className="search-logo"
					src={process.env.PUBLIC_URL + '/static/header/search.svg'}
					alt="search" 
				/>
				<img 
					className="user-icon"
					src={process.env.PUBLIC_URL + '/static/header/user_icon.png'}
					alt="user-icon" 
				/>
				<div>Hafsa El akhdar</div>
			</div>
		</div>
	)
}

Header.displayName="Header";