// App imports
import { Selectors } from './selectors';
import './styles.scss';

export const Filters = () => {
	const currentSelectors = [ 
		"eating", "education", "finantial", 
		"government", "health", "industrial", "lodging",  
		"religions", "service", "shop", "transportation"
	];

	return (
		<div className="poi-filters-wrapper">
			<Selectors items={currentSelectors}/>
		</div>
	)
}

Filters.displayName="Filters";