// App imports
import './styles.scss';

export const Selectors = ({ items }: any) => {
	return items.map((item: string) => (
		<div key={item} className="left-item">
			<img 
				src={`${process.env.PUBLIC_URL}/static/left/${item}.svg`} 
				alt={item} 
			/>
		</div>
	));
};

Selectors.displayName="Selectors";