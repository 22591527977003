// App imports
import { Wrapper } from '../wrapper';
import './styles.scss';

// Context imports
import { useGoogle } from 'context/google';
import { useMapbox } from 'context/mapbox';

// Third-party imports
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

export const Google = () => {
  const { googleMapRef } = useGoogle();
  const { viewport } = useMapbox();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });

  const mapContainerStyle = { width: '100%', height: '100%' };

  const { latitude, longitude, zoom } = viewport
  const center = { lat: latitude, lng: longitude };

  return isLoaded ? (
    <Wrapper>
      <GoogleMap
        onLoad={(map: any) => (googleMapRef.current = map)}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
      >
      </GoogleMap>
    </Wrapper>
  ) : (
    <div>Loading...</div>
  );
};

Google.displayName = 'Google';