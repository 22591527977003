// App imports
import { Subtitle } from './subtitle';
import { CTA } from './cta';
import './styles.scss';

export const Intro = () => {
	return (
		<div className="intro">
			<div className="intro-title">hello!</div>
			<Subtitle/>
			<div className="providers-selector-wrapper">
				<CTA provider="mapbox"/>
				<CTA provider="google"/>
				<CTA provider="leaflet"/>
				<CTA provider="openlayers"/>
			</div>
		</div>
	)
}

Intro.displayName="Intro";