// Third-party imports
import VectorTileLayer from 'ol/layer/VectorTile';
import MVT from 'ol/format/MVT';
import VectorTileSource from 'ol/source/VectorTile';
import { Style, Fill, Stroke } from 'ol/style';

export const Tiles = ({ map }: any) => {
  const tableSchema = "kuwait";
  const tableName = "neighborhood"

  const vectorTileLayer = new VectorTileLayer({
    source: new VectorTileSource({
      format: new MVT(),
      url: `
        ${process.env.REACT_APP_API_URL}
        /tiles
        ?table_schema=${tableSchema}
        &table_name=${tableName}
        &x={x}
        &y={y}
        &z={z}
      `.replace(/\s/g, '')
    }),

    style: (feature: any) => {
      return new Style({
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',  // Blue fill with transparency
        }),
        stroke: new Stroke({
          color: '#3399CC',  // Blue stroke color
          width: 1.5,
        }),
      });
    },
  });

  map && map.addLayer(vectorTileLayer);
  
  return (
    <></>
  );
};

Tiles.displayName="Tiles";