// React imports
import { useState, useEffect } from 'react';

// Context imports
import { useStylesApi } from 'context/api/styles';
import { useMapbox } from 'context/mapbox';

// Third party imports
import { Source, Layer } from 'react-map-gl';

export const Tiles = ({ tableName, tableSchema, tilesId, visibility }: any) => {
	const { fetchData, getTilesUrl } = useStylesApi();
	const { mapRef } = useMapbox();

	const [ styleData, setStyleData ] = useState<any[]>([]);

    useEffect(() => {
    	const loadData = async () => {
			const data = await fetchData('', tableName);
			setStyleData(data);
		}
		loadData();
	}, []);

	const url = getTilesUrl(tableSchema, tableName);

	useEffect(() => {
		const currentMap = mapRef.current;
		const map = currentMap.getMap();
		
		if (map && styleData.length > 0) {
			// Update visibility of each layer dynamically
			styleData.forEach((style) => {
				if (map.getLayer(style.id)) {
					map.setLayoutProperty(
						style.id,
						'visibility',
						visibility ? 'visible' : 'none'
					);
				}
			});
		}
	}, [ mapRef.current, styleData, visibility ]);

	const layers = styleData.map((style: any, index: number) => {
		return (
			<Layer key={`${style.source}.${index}`} {...style}/>
		)
	});

	return (
		<Source 
			id={tilesId} 
			type="vector" 
			tiles={[ url ]}
			// minzoom={13}
		>
			{layers}
		</Source>
	)
}

Tiles.displayName="Tiles"