export const kuwait = {
  "default": {
    latitude: 29.3718702,
    longitude: 47.9791191,
    zoom: 13,
  },
  "Al Ahmadi": {
    latitude: 29.0824,
    longitude: 48.1303,
    zoom: 13,
  },
  "Al Asimah": {
    latitude: 29.3759,
    longitude: 47.9774,
    zoom: 13,
  },
  "Al Jahra": {
    latitude: 29.3366,
    longitude: 47.6675,
    zoom: 13,
  },
  "Farwaniya": {
    latitude: 29.2727,
    longitude: 47.9685,
    zoom: 13,
  },
  "Hawalli": {
    latitude: 29.3339,
    longitude: 48.0283,
    zoom: 13,
  },
  "Mubarak Al-Kabeer": {
    latitude: 29.2065,
    longitude: 48.0473,
    zoom: 13,
  },
};