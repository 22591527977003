// App imports
import { Selectors } from './selectors'
import './styles.scss';

const currentSelectors = [ "home", "printer", "3d", "distance", "location", "minus", "plus" ];

export const Left = () => {
	return (
		<div className="left-wrapper">
			<Selectors items={currentSelectors}/>
		</div>
	);
};

Left.displayName = "Left";