// React imports
import { useCallback } from 'react';

// App imports
import { Wrapper } from '../wrapper';
import { Tiles } from './tiles';
import { Buildings } from './buildings';
import { Points } from './points';
import { Mask } from './mask';
import { Circle } from './circle';
import { Avatar } from './avatar';
import { Stats } from './stats';
import './styles.scss';

// Context imports
import { useMapbox } from 'context/mapbox';
import { useVisibility } from 'context/visibility';
import { useCircle } from 'context/circle';
import { useEvents } from 'context/maps/events';

// Third-party imports
import { Map } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export const Main = () => {
	const { mapRef, currentBasemap, viewport, setMarker } = useMapbox();
	const { circleGeometry } = useCircle();
	const { isDragging, onDragStart, onMouseMove, onDragEnd } = useEvents();

	const {
		buildingVisibility,
		landscapePointsVisibility,
		streetsVisibility,
		boundaryVisibility,
	} = useVisibility();

	const onDblClick = useCallback((e: any) => {
		const { lng, lat } = e.lngLat
		setMarker({ longitude: lng, latitude: lat });
	}, []);

	// Define the order of layers
	const layersOrder = [
		// { id: 'boundary-source', visibility: boundaryVisibility, tableName: 'boundary' },
		{ id: 'streets-source', visibility: streetsVisibility, tableName: 'streets' },
		// { id: 'trees-source', visibility: landscapePointsVisibility, tableName: 'trees' },
		{ id: 'neighborhood-source', visibility: landscapePointsVisibility, tableName: 'neighborhood' },
	];

	const onMapLoad = () => {
	  const map = mapRef.current?.getMap();
	  
	  if (map) {
	    map.addSource('eraser', {
	      type: 'geojson',
	      data: circleGeometry.geometry,
	    });

	    map.addLayer({
	      id: 'eraser',
	      type: 'clip',
	      source: 'eraser',
	      layout: {
	        'clip-layer': ['building-extrusion']
	      },
	      minzoom: 14
	    });
	  }
	};

	return (
		<Wrapper>
			<Map
				ref={mapRef}
				initialViewState={viewport}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				mapStyle={currentBasemap}
				onDblClick={onDblClick}
				doubleClickZoom={false}
				// onLoad={onMapLoad}
		        onMouseDown={onDragStart}
		        onMouseMove={onMouseMove}
		        onMouseUp={onDragEnd}
		        onTouchStart={onDragStart}
		        onTouchMove={onMouseMove}
		        onTouchEnd={onDragEnd}
		        dragPan={!isDragging}
			>
				{layersOrder.map((layer: any) => (
					<Tiles
						key={layer.id}
						tableSchema="kuwait"
						tableName={layer.tableName}
						tilesId={layer.id}
						visibility={layer.visibility}
					/>
				))}
				{/*{buildingVisibility && <Buildings/>}*/}
				<Circle/>
				<Points/>
				<Mask/>
				<Avatar/>
			</Map>
			<Stats/>
		</Wrapper>
	)
}

Main.displayName="Main";