// React imports
import { useState, useEffect, useContext, createContext } from 'react';

const VisibilityContext: React.Context<any> = createContext(null)

export const useVisibility = () => {
	return (
		useContext(VisibilityContext)
	)
}

export const VisibilityProvider = ({children}: any) => {
	// Layers visibility
	const [ buildingVisibility, setBuildingVisibility ] = useState(true);
	const [ landscapePointsVisibility, setLandscapePointsVisibility ] = useState(true);
	const [ poisVisibility, setPoisVisibility ] = useState(true);
	const [ streetsVisibility, setStreetsVisibility ] = useState(true);
	const [ boundaryVisibility, setBoundaryVisibility ] = useState(true);

	// Other items visibility
	const [ poiFiltersVisibility, setPoiFiltersVisibility ] = useState(false);

	return (
		<VisibilityContext.Provider value={{
			buildingVisibility, setBuildingVisibility,
			landscapePointsVisibility, setLandscapePointsVisibility,
			poisVisibility, setPoisVisibility,
			streetsVisibility, setStreetsVisibility,
			boundaryVisibility, setBoundaryVisibility,
			poiFiltersVisibility, setPoiFiltersVisibility,
		}}>
			{children}
		</VisibilityContext.Provider>
	)
}

VisibilityContext.displayName = "VisibilityContext";