// App imports
import './styles.scss';

export const Selectors = ({ items }: any) => {
	return items.map((item: string) => (
		<div className="filters-item">
			<img 
				key={item}
				src={`${process.env.PUBLIC_URL}/static/filters/${item}.svg`} 
				alt={item} 
			/>
		</div>
	));
};

Selectors.displayName="Selectors";