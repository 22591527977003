// React imports
import { useState } from 'react';

// App imports
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const Home = () => {
	const navigate = useNavigate();

	const [ activeMobile, setActiveMobile ] = useState(false);

	const onClick = () => {
		navigate('intro');
	}

	window.addEventListener('resize', () => {
	  let innerWidth = window.innerWidth;
	  innerWidth > 768 ? 
	  setActiveMobile(false) : 
	  setActiveMobile(true)
	});

	const backgroundUrl = process.env.PUBLIC_URL + '/static/background/background.png';
	const backgroundMobileUrl = process.env.PUBLIC_URL + '/static/background/background_mobile.png';
	
	return (
		<div
			className="home-wrapper"
			style={{
				backgroundImage: 
					activeMobile || window.innerWidth < 768 ?
					`url(${backgroundMobileUrl})` :
					`url(${backgroundUrl})`
			}}
		>
			<div className="home">
				<div className="home-title">
					<img 
						className="logo-home" 
						src={process.env.PUBLIC_URL + "/static/logos/logo.webp"} 
						alt="logo"
					/>
				</div>
				<div className="home-subtitle">
					Locate your business <br/>
					on Kuwait maps
				</div>
				<div 
					className="home-cta"
					 onClick={onClick}
				>
					JOIN US NOW
				</div>
			</div>
		</div>
	)
}

Home.displayName="Home";