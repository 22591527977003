// React imports
import { useState, useRef, useEffect, useContext, createContext } from 'react';

// App imports
import * as Locations from './locations';

const MapboxContext: React.Context<any> = createContext(null);

export const useMapbox = () => {
	return (
		useContext(MapboxContext)
	)
}

export const MapboxProvider = ({children}: any) => {
	const mapRef = useRef<any>();
	const [ placeId, setPlaceId ] = useState<any>(null);
	const [ currentBasemap, setCurrentBasemap ] = useState("mapbox://styles/hvoking/cm3e327gx005h01ql949v4ahe");
	const [ viewport, setViewport ] = useState(Locations.kuwait.default);
	
	const [ marker, setMarker ] = useState({ 
		latitude: viewport.latitude, 
		longitude: viewport.longitude 
	});

	useEffect(() => {
		mapRef.current?.flyTo({
			center: [ viewport.longitude, viewport.latitude ],
			zoom: viewport.zoom,
			duration: 3000, 
			essential: true,
		});
		setMarker({
			longitude: viewport.longitude,
			latitude: viewport.latitude,
		});
	}, [ viewport ]);

	return (
		<MapboxContext.Provider value={{
			mapRef,
			currentBasemap, setCurrentBasemap,
			viewport, setViewport,
			marker, setMarker,
			placeId, setPlaceId
		}}>
			{children}
		</MapboxContext.Provider>
	)
}

MapboxContext.displayName = "MapboxContext";