// App imports
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const CTA = ({ provider }: any) => {
	const navigate = useNavigate();
	const onClick = () => navigate(`/${provider}`);
	
	return (
		<div className="card-wrapper" onClick={onClick}>
			<div className="card-title">
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<div 
						className="pages-description" 
						style={{paddingTop: "5px", paddingBottom: "5px"}}
					>
						{provider}
					</div>
					<img 
						src={process.env.PUBLIC_URL + "/static/extra/redirect_white.svg"} 
						width="15px" 
						alt="redirect"
						onClick={onClick}
					/>
				</div>
			</div>
			<img 
				width="300px"
				className="card-thumbnail" 
				src={process.env.PUBLIC_URL + `/static/providers/${provider}.png`} 
				alt={"provider-thumbnail"}
			/>
			<div className="card-background"/>
		</div>
	)
}

CTA.displayName="CTA";