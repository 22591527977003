// Routes
import { Home } from 'components/home';
import { Intro } from 'components/intro';
import { About } from 'components/about';
import { Main } from 'components/providers/mapbox';
import { Google } from 'components/providers/google';
import { LeafletMap } from 'components/providers/leaflet';
import { OpenLayersMap } from 'components/providers/openlayers';

// Third party imports
import { Routes, Route } from 'react-router-dom';

export const TheDotRouter = () => {
	return (
		<Routes>
			<Route path='/' element={<Home/>}/>
			<Route path='/intro' element={<Intro/>}/>
			<Route path='/about' element={<About/>}/>
			<Route path='/mapbox' element={<Main/>}/>
			<Route path='/google' element={<Google/>}/>
			<Route path='/leaflet' element={<LeafletMap/>}/>
			<Route path='/openlayers' element={<OpenLayersMap/>}/>
		</Routes>
	)
}

TheDotRouter.displayName="TheDotRouter"