// App imports
import { Right } from './right';
import { Filters } from './filters';
import { Left } from './left';
import { Header } from './header';
import { Search } from './search';
import './styles.scss';

// Context imports
import { useVisibility } from 'context/visibility';


let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
	  let vh = window.innerHeight * 0.01;
	  document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

export const Wrapper = ({ children }: any) => {
	const { poiFiltersVisibility, setPoiFiltersVisibility } = useVisibility();
	
	return (
		<div className="wrapper">
			<Header/>
			<div className="map-wrapper">
				<Right setPoiFiltersVisibility={setPoiFiltersVisibility}/>
				<Search/>
				{poiFiltersVisibility && <Filters/>}
				{children}
				<Left/>
			</div>
		</div>
	)
}

Wrapper.displayName="Wrapper";