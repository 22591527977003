// React imports
import  { useRef, useEffect } from 'react';

// App imports
import { Wrapper } from '../wrapper';

// Context imports
import { useMapbox } from 'context/mapbox';

// Third-party imports
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export const LeafletMap = () => {
    const leafletRef = useRef<HTMLDivElement | null>(null);

    const { viewport } = useMapbox();
    const { latitude, longitude, zoom } = viewport;

    useEffect(() => {
        if (!leafletRef.current) return;

        // Initialize Leaflet map
        const map = L.map(leafletRef.current, {
            center: [ latitude, longitude ],
            zoom: zoom,
            minZoom: 3,
            maxZoom: 18,
        });

        // Add TileLayer
        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: ['a', 'b', 'c', 'd'],
            maxZoom: 18,
        }).addTo(map);

        // Add scale control
        L.control.scale({ position: 'bottomleft', imperial: true, metric: true }).addTo(map);

        return () => {
            map.remove();
        };
    }, []);

    return (
        <Wrapper>
            <div
                ref={leafletRef}
                style={{ height: '100vh', width: '100%' }}
            />
        </Wrapper>
    );
};

LeafletMap.displayName = "LeafletMap";