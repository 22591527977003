// App imports
import { ApiProvider } from './api';
import { MapboxProvider } from './mapbox';
import { VisibilityProvider } from './visibility';
import { GoogleProvider } from './google';
import { CircleProvider } from './circle';
import { MapsProvider } from './maps';
import { SizesProvider } from './sizes';

export const MainProvider = ({children}: any) => {
  return (
    <MapboxProvider>
    <CircleProvider>
    <GoogleProvider>
    <ApiProvider>
    <VisibilityProvider>
    <MapsProvider>
    <SizesProvider>
      {children}
    </SizesProvider>
    </MapsProvider>
    </VisibilityProvider>
    </ApiProvider>
    </GoogleProvider>
    </CircleProvider>
    </MapboxProvider>
  )
}

MainProvider.displayName="MainProvider";