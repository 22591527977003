// App imports
import './styles.scss';

export const About = () => {
	return (
		<div className="about-wrapper">
			<div className="sidebar-wrapper">
				<img
					className="sidebar-logo"
					src={process.env.PUBLIC_URL + "/static/logos/logo.png"} 
					alt="logo" 
					
				/>
				<div className="sidebar-selectors">
					<div>User Details</div>
					<div>Collaborators and Team</div>
					<div>Saved Locations</div>
					<div>My Business</div>
					<div>Dashboard</div>
					<div>About Us</div>
					<div>Subscriptors</div>
					<div>Support cases</div>
					<div>FAQs</div>
				</div>
				<div className="sidebar-cta">
					<img 
						className="support-team" 
						src={process.env.PUBLIC_URL + "/static/extra/support_team.png"} 
						alt="support-team"
					/>
					<div>Support Team</div>
				</div>
			</div>
			<div className="about-main"></div>
		</div>
	)
}

About.displayName="About";